<template>
  <nav class="navbar navbar-expand-lg heidornline">
    <div class="container">
     
        <ul class="navbar-nav me-auto fw-bold ">
          <li class="nav-item active">
            <router-link to="/" class="nav-link headerLogo">
              <img src="../assets/LOGO2.png"
                  class="img-fluid pt-3"
                  alt="..." />
            </router-link>
          </li>
          <li class="nav-item active">
            <router-link to="/Impressum" class="nav-link">Impressum</router-link>
          </li>
          <li class="nav-item active">
            <router-link to="/Datenschutz" class="nav-link">Datenschutz</router-link>
          </li>
          
          
        </ul>
     
     
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";


export default {
  name: "TheNavbar",
  computed: {
    ...mapGetters({ activeScreen: "getActiveSite" })
  },
  methods: {
  },
  data() {
    return {
     
    };
  },
  created() {
   
  }
};
</script>

<style scoped></style>
