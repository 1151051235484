<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid p-0 titelBackground pb-5">
          <div class="row justify-content-center">
            <div class="col-md-6 text-center pt-5 mt-5  Textblock">
              <h1>Impressum</h1>

              <h2>Heidorn GmbH</h2>
              <h2>Breitscheitstraße 7a</h2>
              <h2 class="pb-5">07407 Rudolstadt</h2>

              <h2 class="">
                <a href="mailto:heidorn-cnc@gmx.de" class="email"
                  >heidorn-cnc@gmx.de</a>
              </h2>
              <h3 class=" pb-5">
                <a title="Telefon" href="tel:036741189160" target="_blank" rel="noopener">
                  <i class="fas fa-phone"></i>
                </a>
                <a href="tel:036724892626">03672 489 2626</a>        
              </h3>
              <h4>Steuer ID: DE 270523170</h4>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";

export default {
  name: "Impressum",

  data() {
    return {
      newSite: null,
    };
  },
  components: {
    Layout,
  },
  methods: {},
  computed: {},
};
</script>

<style scoped></style>
